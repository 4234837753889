/*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*
Order Now
*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*/

.total-price, .phone-number {
	margin-bottom: 0;
	margin-left: 20px;
	margin-right: 20px;
}

.delivery-included, .order-summary {
	margin-top: 0;
}

.total-price {
	margin-top: 40px;
}

.delivery-included {
	color: grey;
	padding-bottom: 20px;
}

.order-now-content {
	justify-items: center; 
	display: grid;
	gap: 25px;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	padding: 20px;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	overflow: hidden;
	/* background-color: red; */
}

/*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*
Card
*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*/

.card {
	border: 1px solid gray;
	width: 100%;
	height: 100%;
	text-align: left;
	background: linear-gradient(0, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("../images/Food.jpg");
	background-size: cover;
	/* background-blend-mode: soft-light; */
}

.card:hover {
	transform: scale(1.01);
	box-shadow: 2px 2px 5px black;
}

.card_content {
	margin: 5% 5%;
	font-size: 20px;
	/* background-color: blue; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 90%;
}

.card_content > img {
	width: 40px;
}

.card_selector-container {
	/* background-color: red; */
	padding-bottom: 2%;
	display: flex;
	justify-content: space-between;
}

.card_selector-container > * {
	flex: 1;
}

.card_content > p {
	line-height: 1.2;   /* line height within paragraph */
  	margin-bottom: 1.5; /* line height between paragraphs */
}

/*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*
Meal Plan Selector
Custom Radio-buttons: https://www.markheath.net/post/customize-radio-button-css
*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*/

.meal-plan-selector {
	opacity: 0;
	position: fixed;
	width: 0;
}

.meal-plan-selector + label {
	display: inline-block;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 10px 0;
	margin: 0 1%;
    font-size: 16px;
	line-height: 1.5;
    border: 1.5px solid #444;
    border-radius: 4px;
	text-align: center;
}

.meal-plan-selector:checked + label {
    background-color:rgba(30, 235, 10, 0.4);
}

/*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*
Add-on Selector
*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*/

.add-on-selector-container {
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	font-size: 20px;
	line-height: 2.2;
	padding-bottom: 50px;
}

.add-on-selector {
	width: 20px;
	height: 20px;
	accent-color: rgb(80, 120, 60);
}

.add-on-selector:disabled + label {
	text-decoration: line-through;
	color: lightgray;
}
