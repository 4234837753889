.App {
  text-align: center;
  overflow-y: scroll;
  font-family: Futura, sans-serif;
}

/*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*
Banner
*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*/

@keyframes carousel {
    0%   { transform: translate(0); }
	40%	 { transform: translate(0); }
	50%	 { transform: translate(-100vw); }
	90%	 { transform: translate(-100vw); }
    100% { transform: translate(-200vw); }
}

.banner {
	background-color: rgb(247, 130, 35);
	height: 40px;
	width: 100vw;
    display: flex;
    flex-wrap: nowrap;
	overflow: hidden;
}

.banner_item {
	/* duration | easing-function | delay | iteration | fille-mode | name */
	/* animation: 15s linear 0s infinite carousel; */
	display: flex;           /* Center content */
	align-items: center;     /* Center content */
	justify-content: center; /* Center content */
	flex-shrink: 0;
	height: inherit;
	width: 100vw;
	font-size: max(10px, 1.2vw);
}

/*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*
Header
*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*/

header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.5px;
} 

.logo {
	width: min(max(15%, 100px), 180px);
	padding: max(2%, 15px) 0; /* top and bottom | left and right */
}

.title {
	color: rgb(100, 150, 75);
}

#show-navbar-button {
	position: absolute;
	left: 1px;
	font-size: 30px;
	color: black;
	background-color: transparent;
	border: none;
}

#hide-navbar-button {
	text-align: right;
	font-size: 35px;
	color: white;
	background-color: transparent;
	border: none;
}

/* Nav is row of buttons */
@media (min-width: 601px) {
	nav {
		display: flex;
		width: 100% !important; /* Must override animation */
		max-width: 700px;
		justify-content: space-evenly;
		padding-bottom: 2%;
	}
	#show-navbar-button, #hide-navbar-button, .title {
		display: none;
	}
	.nav-link {
		color: #5c5c5c;
	}
}

/* Nav is sidebar */
@media (max-width: 600px) {
	nav {
		display: flex;
		flex-direction: column;
		gap: 5%;
		height: 100%;
		width: 0; 
		position: fixed; 
		z-index: 1; 
		top: 0; 
		left: 0;
		background-color: rgba(0, 0, 0, 0.8); 
		overflow-x: hidden; 
		padding-top: 60px; 
	}
	.nav-link {
		color: white;
	}
	.logo {
		padding-bottom: 0; /* Reduce padding between logo & title */
	}
	.home-subheader_content > h4 {
		display: none; /* Too small on a little screen */
	}
}

.nav-link {
	font-size: 15px;
	width: fit-content;
	white-space: nowrap;
	border: none;
	background-color: transparent;
	cursor: pointer;
}

/*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*
Content
*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*/

.text-block {
	color: #524b4b;
	font-family: "Century Gothic", sans-serif;
	font-size: 24px;
	font-weight: lighter;
	font-style: normal;
	text-align: start;
	padding: 50px 10%;
}

.text-block > p {
	line-height: 2;   /* line height within paragraph */
  	margin-bottom: 3; /* line height between paragraphs */
}

.order-now-link {
	color: rgb(100, 150, 75);
}

/*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*
Home 
*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*/

@keyframes shrinkAndFadeIn {
	0% {
		transform: matrix(1.2, 0, 0, 1.2, 0, 0);
		opacity: 0;
	}
	100% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 0.3;
	}
}

@keyframes riseAndFadeIn {
	0% {
		transform: translate(0, 10px);
		opacity: 0;
	}
	100% {
		transform: translate(0, 0);
		opacity: 1;
	}
}

.home-subheader {
	background-color: black;
	overflow: hidden;
	position: relative; /* Needed for positioning absolute content */
	text-align: center;
}

.home-subheader_image {
	/* duration | easing-function | delay | iteration | fille-mode | name */
	animation: 3.5s ease-out 0s 1 forwards shrinkAndFadeIn;
	max-width: 100%;
	display: block;
}

.home-subheader_content {
	/* duration | easing-function | delay | iteration | fille-mode | name */
	animation: 3s ease-out 0.5s 1 both riseAndFadeIn;
	color: white;
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: fit-content;
	font-size: 1.4vw;
}

/*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*
Menu
*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*/

.menu-image {
	margin-top: 10%;
	margin-bottom: 10%;
	width: 90%;
}

/*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*
FAQ 
*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*/

.question {
	/* margin-bottom: 80px; */
	/* font-size: 30px; */
	text-align: center;
}

